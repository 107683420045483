import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill'

// needed for safar 12
export const intlNumberformatPolyfill = async () => {
  try {
    if (shouldPolyfill()) {
      await Promise.all([
        import(
          /* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/polyfill'
        ),
        import(
          /* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/polyfill'
        ),
        import(
          /* webpackChunkName: "intl-locale" */ '@formatjs/intl-locale/polyfill'
        ),
      ])
    }

    if (Intl.NumberFormat.polyfilled) {
      switch (process.env.GATSBY_COUNTRY) {
        case 'IT':
          await Promise.all([
            import(
              /* webpackChunkName: "intl-pluralrules-locale-it" */ '@formatjs/intl-pluralrules/locale-data/it'
            ),
            import(
              /* webpackChunkName: "intl-numberformat-locale-it" */ '@formatjs/intl-numberformat/locale-data/it'
            ),
          ])
          break
        case 'GB':
        default:
          await Promise.all([
            import(
              /* webpackChunkName: "intl-pluralrules-locale-en" */ '@formatjs/intl-pluralrules/locale-data/en'
            ),
            import(
              /* webpackChunkName: "intl-numberformat-locale-en" */ '@formatjs/intl-numberformat/locale-data/en'
            ),
          ])
          break
      }
    }
  } catch (e) {}
}
