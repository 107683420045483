import React, { Fragment } from 'react'

import {
  ImageBreakpoints,
  ImageData,
  type ImageAtSizeData,
} from '../../types/Image'
import { acceptableFileTypesForWebp } from '../../utils/fileTypesForWebP'
import gridTheme from '../../utils/gridTheme'

const breakpoints = gridTheme.breakpoints

/**
 * Checks if the image URL is from the local backend, if so it is not going
 * to get optimised by the CDN.
 */
function hasOptimisedAsset(imageData?: ImageData) {
  return (
    imageData?.subtype &&
    acceptableFileTypesForWebp.test(imageData?.subtype) &&
    !imageData?.url?.includes('local-backend.moneyfarm.com')
  )
}

function getActuallySpecifiedBreakpoints(
  breakpointsImageData: BreakpointsImagesData
) {
  return Object.values(breakpointsImageData).filter(
    (imageDataAtSize) => !!imageDataAtSize?.id
  )
}

function hasOnlyOneBreakpoint(breakpointsImageData: BreakpointsImagesData) {
  return getActuallySpecifiedBreakpoints(breakpointsImageData).length === 1
}

function onlySpecifiedBreakpoint(breakpointsImageData: BreakpointsImagesData) {
  return getActuallySpecifiedBreakpoints(breakpointsImageData)[0]
}

export type BreakpointsImagesData = {
  xs: ImageData
  sm?: ImageData
  md?: ImageData
  lg?: ImageData
  xl?: ImageData
}

function multipleImagesSources(breakpointsImageData: BreakpointsImagesData) {
  return Object.keys(breakpointsImageData).map((breakpoint, index) => {
    const typesafeBreakpoint = breakpoint as ImageBreakpoints

    if (breakpointsImageData[typesafeBreakpoint]) {
      const imageData = breakpointsImageData[typesafeBreakpoint]
      const imageUrl = imageData?.url
      const mediaQuery = `(min-width: ${breakpoints[typesafeBreakpoint]}px)`

      return (
        <Fragment key={index}>
          {hasOptimisedAsset(imageData) && (
            <source
              media={mediaQuery}
              srcSet={`${imageUrl}.webp`}
              type="image/webp"
            />
          )}

          <source media={mediaQuery} srcSet={imageUrl} type={imageData?.mime} />
        </Fragment>
      )
    }
  })
}

function singleImageSources(imageData: ImageData) {
  if (imageData.mime === 'image/svg+xml') return null

  return (
    <Fragment>
      {Object.entries(imageData.sizes)
        .filter(([sizeName]) => sizeName !== 'thumbnail')
        .sort(([, { width: width1 }], [, { width: width2 }]) => width2 - width1)
        .map(([sizeName, { width, url }], index, list) => {
          /**
           * Get the URLs for the base and the WebP version of the image.
           * If we're not at the last breakpoint, then we add the next
           * breakpoint's URL for the 2x version of the source URL.
           */
          const getUrls = (
            url: string,
            index: number,
            list: [string, ImageAtSizeData][]
          ) => {
            if (index <= 0) return { base: url, webp: `${url}.webp` }

            return {
              base: `${url} 1x, ${list[index - 1][1].url} 2x`,
              webp: `${url}.webp 1x, ${list[index - 1][1].url}.webp 2x`,
            }
          }

          const { base, webp } = getUrls(url, index, list)

          return (
            <Fragment key={sizeName}>
              {hasOptimisedAsset(imageData) && (
                <source
                  media={`(min-width: ${width}px)`}
                  srcSet={webp}
                  type="image/webp"
                />
              )}

              <source
                media={`(min-width: ${width}px)`}
                srcSet={base}
                type={imageData?.mime}
              />
            </Fragment>
          )
        })}
    </Fragment>
  )
}

/**
 * This function returns a list of sources for a `<picture>` element, given a
 * record of image data associated to specific breakpoints. If image data is
 * only specified for a single breakpoint, then the sources are generated for
 * different view widths using progressively bigger images.
 */
export function sources(breakpointsImageData: BreakpointsImagesData) {
  // If actually different images are specified for different breakpoints.
  if (!hasOnlyOneBreakpoint(breakpointsImageData))
    return multipleImagesSources(breakpointsImageData)

  // Otherwise, generate a list of sources using progressively bigger images for
  // the only breakpoint specified.
  return singleImageSources(
    onlySpecifiedBreakpoint(breakpointsImageData) as ImageData
  )
}
