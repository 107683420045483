import React, { FC } from 'react'

import { DefaultTheme } from 'styled-components'

type Props = {
  theme: DefaultTheme
}

export const UnsupportedBrowserScript: FC<Props> = ({ theme }) =>  (
    <script type="text/javascript">
      {`
  (function () {
    document.addEventListener('DOMContentLoaded', function () {
      var re=${process.env.GATSBY_BROWSERSLIST} || new RegExp()
      if (!re.test(navigator.userAgent) && document.cookie.indexOf("mfm-ub=snooze") == -1) {
        var t={
          en: {t:'Unsupported browser',d:'Update your browser for more security, speed and the best experience on this site.',
            u:'Update browser',i:'Ignore',url:'https://browsehappy.com/?locale=en'},
          it: {t:'Il tuo browser non è supportato',d:'Aggiorna il browser per una maggiore sicurezza, velocità e la migliore esperienza su questo sito.',
            u:'Aggiorna browser',i:'Ignora',url:'https://browsehappy.com/?locale=it'}}
        var n=window.navigator, l=((n.languages ? n.languages[0] : null) || n.language || n.browserLanguage || n.userLanguage || document.documentElement.getAttribute("lang") || "en").substr(0,2);
        var div=document.createElement("div");div.id="ub";
        var style='<style type="text/css">'
          + '#ub {font-family:${theme.typography.font.family.text};border-radius:4px;box-shadow:0 4px 14px 3px ${theme.tokens.interface.shadow.regular};position:fixed;z-index:111111;background-color:#fff;padding:16px;right:14px;top:70px;width:305px;line-height:1.5;}'
          + '#ub b {display:inline-block;margin-bottom: 8px;}'
          + '#ub button {margin-top: 16px; display:inline-block;text-align:center;padding:10px 16px;line-height:20px;font-family:${theme.typography.font.family.text};font-weight:bold;font-size:16px;border-radius:4px;cursor:pointer;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}'
          + '#ub #ub-u {color:${theme.tokens.accent.onToken.primary.regular};background-color:${theme.tokens.accent.token.primary.regular};border:solid 2px ${theme.tokens.accent.token.primary.regular}}'
          + '#ub #ub-i {color:${theme.tokens.accent.base.primary.regular};background-color:transparent;border:solid 2px ${theme.tokens.accent.base.primary.regular};margin-left:8px;}'
          + '</style>';
        div.innerHTML='<b>'+t[l].t+'</b>'+'<p>'+t[l].d+'</p>'+'<button id="ub-u">'+t[l].u+'</button>'+'<button id="ub-i">'+t[l].i+'</button>'+style
        if(document.body){document.body.insertBefore(div, document.body.firstChild)};
        var uBtn=document.getElementById("ub-u"),iBtn=document.getElementById("ub-i"),ub=document.getElementById("ub");
        if(iBtn && ub){iBtn.onclick=function(){ub.style.display='none';document.cookie='mfm-ub=snooze; expires='+(new Date(new Date().getTime()+3600000*24)).toGMTString()+'; path=/; SameSite=Lax; Domain=.moneyfarm.com';}}
        if(uBtn && ub){uBtn.onclick=function(){window.open(t[l].url, '_blank');}}
      }
    });
  })();
`}
    </script>
  )
