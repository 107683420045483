import styled from 'styled-components'

export const Title = styled.h1`
  color: #00000040;
  font-size: 32px;
  text-align: center;
  border-bottom: 1px solid #00000020;
  padding-bottom: 16px;
`

export const Container = styled.section`
  background-color: #dfdfdf;
  margin: 32px;
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TraceContainer = styled.div`
  background-color: #00000010;
  color: Grey;
  text-align: left;
  padding: 16px 32px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: monospace;
  margin-top: 16px;
`
