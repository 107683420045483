import React from 'react'
import loadable from '@loadable/component'

import parser from '../utils/htmlParser'
import { BlockErrorBoundary } from './BlockErrorBoundary/BlockErrorBoundary'
import Image from './blocks/mfblocks/Image'
import CustomHtml from './blocks/core/CustomHtml'

// --- Components
const Hero = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/Hero')
)
const Paragraph = loadable(
  () => import(/* webpackPreload: true */ './blocks/core/Paragraph')
)
const BootstrapColumns = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/BootstrapColumns')
)
const BootstrapColumn = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/BootstrapColumn')
)
const Heading = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/Heading')
)
const Button = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/Button')
)
const Link = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/Link')
)
const Icon = loadable(() => import('./blocks/mfblocks/Icon'))
const Stripe = loadable(
  () => import(/* webpackPreload: true */ './blocks/mfblocks/Stripe')
)
const YouTubeVideo = loadable(() => import('./blocks/core/YouTubeVideo'))
const IconFeature = loadable(() => import('./blocks/mfblocks/IconFeature'))
const FeatureCard = loadable(() => import('./blocks/mfblocks/FeatureCard'))
const Blockquote = loadable(() => import('./blocks/mfblocks/Blockquote'))
const Timeline = loadable(() => import('./blocks/mfblocks/Timeline'))
const Table = loadable(() => import('./blocks/mfblocks/Table'))
const List = loadable(() => import('./blocks/mfblocks/List'))
const Notice = loadable(() => import('./blocks/mfblocks/Notice'))
const OnPageNav = loadable(() => import('./blocks/mfblocks/OnPageNav'))
const Accordion = loadable(() => import('./blocks/mfblocks/Accordion'))
const Pricing = loadable(() => import('./blocks/mfblocks/Pricing'))
const PressItem = loadable(() => import('./blocks/mfblocks/PressItem'))
const FeatureBox = loadable(() => import('./blocks/mfblocks/FeatureBox'))
const FAQs = loadable(() => import('./blocks/mfblocks/FAQs'))
const Map = loadable(() => import('./blocks/mfblocks/Map'))
const CallForm = loadable(() => import('./blocks/mfblocks/CallForm'))
const Team = loadable(() => import('./blocks/mfblocks/Team'))
const PensionCalc = loadable(() => import('./blocks/mfblocks/PensionCalc'))
const Tabs = loadable(() => import('./blocks/mfblocks/Tabs'))
const Hr = loadable(() => import('./blocks/mfblocks/Hr'))
const Trustpilot = loadable(() => import('./blocks/mfblocks/Trustpilot'))
const Glossary = loadable(() => import('./blocks/mfblocks/Glossary'))
const ModelPortfolios = loadable(
  () => import('./blocks/mfblocks/ModelPortfolios')
)
const AssetsBreakdown = loadable(
  () => import('./blocks/mfblocks/AssetsBreakdown')
)
const InvestorProfileTabs = loadable(
  () => import('./blocks/mfblocks/InvestorProfileTabs')
)
const LottieAnimation = loadable(
  () => import('./blocks/mfblocks/LottieAnimation')
)
const ExpectedPerformance = loadable(
  () => import('./blocks/mfblocks/ExpectedPerformance')
)
const PricingCardsList = loadable(
  () => import('./blocks/mfblocks/PricingCardsList')
)
const LightCarousel = loadable(() => import('./blocks/mfblocks/LightCarousel'))
const BlogEntries = loadable(() => import('./blocks/mfblocks/BlogEntries'))
const MultiProductPricingCalculator = loadable(
  () => import('./blocks/mfblocks/MultiProductPricingCalculator')
)
const ImagesCarousel = loadable(
  () => import('./blocks/mfblocks/ImagesCarousel')
)
const AppDownloadButton = loadable(
  () => import('./blocks/mfblocks/AppDownloadButton')
)
const Crossword = loadable(() => import('./blocks/mfblocks/Crossword'))

/**
 * Adding childBlock arg so that blocks like the FeatureCard can
 * adjust their size/shape depending on where they are placed on the page (i.e.)
 * contained page width vs inside a column
 */
const renderInnerBlocks = (mediaItems, innerBlocks, childBlock = false) =>
  innerBlocks && innerBlocks.length > 0
    ? innerBlocks.map((innerBlock, innerIndex) =>
        renderBlock(mediaItems, innerBlock, innerIndex, childBlock)
      )
    : null

export const renderBlock = (mediaItems, block, key, childBlock = false) => {
  if (block && typeof render[block.name] === 'function')
    return (
      <BlockErrorBoundary key={`${key}-error`}>
        {render[block.name](mediaItems, block, key, childBlock)}
      </BlockErrorBoundary>
    )

  return `BLOCK NOT BUILT YET: ${
    block ? JSON.stringify({ block, childBlock }) : 'Unknown block'
  }`
}

const render = {
  'core/group': (mediaItems, block) =>
    renderInnerBlocks(mediaItems, block.innerBlocks),

  'mfblocks/hero': (mediaItems, block, key) => {
    return (
      <Hero key={key} {...block.attributes} mediaItems={mediaItems}>
        {renderInnerBlocks(mediaItems, block.innerBlocks)}
      </Hero>
    )
  },
  'mfblocks/stripe': (mediaItems, block, key) => {
    return (
      <Stripe key={key} {...block.attributes} mediaItems={mediaItems}>
        {renderInnerBlocks(mediaItems, block.innerBlocks)}
      </Stripe>
    )
  },
  'mfblocks/paragraph': (_, block, key) => {
    const { content, ...otherAttrs } = block.attributes
    return (
      <Paragraph key={key} {...otherAttrs}>
        {parser(content)}
      </Paragraph>
    )
  },
  'mfblocks/bs-columns': (mediaItems, block, key) => {
    return (
      <BootstrapColumns key={key} {...block.attributes}>
        {renderInnerBlocks(mediaItems, block.innerBlocks)}
      </BootstrapColumns>
    )
  },
  'mfblocks/bs-column': (mediaItems, block, key) => {
    return (
      <BootstrapColumn key={key} {...block.attributes}>
        {renderInnerBlocks(mediaItems, block.innerBlocks, true)}
      </BootstrapColumn>
    )
  },
  'mfblocks/nested-columns': (mediaItems, block, key) => {
    return (
      <BootstrapColumns key={key} {...block.attributes}>
        {renderInnerBlocks(mediaItems, block.innerBlocks)}
      </BootstrapColumns>
    )
  },
  'mfblocks/nested-column': (mediaItems, block, key) => {
    return (
      <BootstrapColumn key={key} {...block.attributes}>
        {renderInnerBlocks(mediaItems, block.innerBlocks, true)}
      </BootstrapColumn>
    )
  },
  'mfblocks/heading': (_, block, key, childBlock) => {
    const { content, ...otherAttrs } = block.attributes

    return (
      <Heading key={key} {...otherAttrs} childBlock={childBlock}>
        {content}
      </Heading>
    )
  },

  'mfblocks/button': (_, block, key) => (
    <Button key={key} {...block.attributes} />
  ),

  'mfblocks/text-link': (_, block, key) => (
    <Link key={key} {...block.attributes} />
  ),

  'mfblocks/icon': (_, block, key) => <Icon key={key} {...block.attributes} />,

  'core-embed/youtube': (_, block, key, childBlock) => (
    <YouTubeVideo key={key} {...block.attributes} childBlock={childBlock} />
  ),

  'mfblocks/icon-feature': (_, block, key, childBlock) => (
    <IconFeature key={key} {...block.attributes} childBlock={childBlock} />
  ),

  'mfblocks/feature-card': (mediaItems, block, key, childBlock) => (
    <FeatureCard key={key} {...block.attributes} childBlock={childBlock}>
      {renderInnerBlocks(mediaItems, block.innerBlocks, true)}
    </FeatureCard>
  ),

  'mfblocks/image': (_, block, key) => (
    <Image key={key} {...block.attributes} />
  ),

  'mfblocks/blockquote': (_, block, key, childBlock) => (
    <Blockquote key={key} {...block.attributes} childBlock={childBlock} />
  ),

  'mfblocks/timeline': (_, block, key, childBlock) => (
    <Timeline key={key} {...block.attributes} childBlock={childBlock} />
  ),

  'mfblocks/table': (_, block, key, childBlock) => (
    <Table key={key} {...block.attributes} childBlock={childBlock} />
  ),

  'mfblocks/notice': (_, block, key) => {
    return <Notice key={key} {...block.attributes} />
  },
  'mfblocks/on-page-nav': (_, block, key) => {
    return <OnPageNav key={key} {...block.attributes} />
  },
  'mfblocks/accordion': (mediaItems, block, key, childBlock) => {
    return (
      <Accordion key={key} {...block.attributes} childBlock={childBlock}>
        {renderInnerBlocks(mediaItems, block.innerBlocks, true)}
      </Accordion>
    )
  },
  'mfblocks/list': (_, block, key, childBlock) => {
    return <List key={key} {...block.attributes} childBlock={childBlock} />
  },
  'mfblocks/pricing': (_, block, key) => (
    <Pricing key={key} {...block.attributes} />
  ),
  'mfblocks/press-item': (_, block, key, childBlock) => {
    return <PressItem key={key} {...block.attributes} childBlock={childBlock} />
  },
  'core/html': (_, block, key) => {
    return (
      <CustomHtml key={key} content={block.saveContent} {...block.attributes} />
    )
  },
  'mfblocks/feature-box': (mediaItems, block, key, childBlock) => {
    return (
      <FeatureBox key={key} {...block.attributes} childBlock={childBlock}>
        {renderInnerBlocks(mediaItems, block.innerBlocks, true)}
      </FeatureBox>
    )
  },
  'mfblocks/faq': (_, block, key, childBlock) => {
    return <FAQs key={key} {...block.attributes} childBlock={childBlock} />
  },
  'mfblocks/map': (_, block, key) => {
    return <Map key={key} {...block.attributes} />
  },
  'mfblocks/call-form': (_, block, key) => {
    return <CallForm key={key} {...block.attributes} />
  },
  'mfblocks/team': (mediaItems, block, key, childBlock) => {
    return (
      <Team
        key={key}
        {...block.attributes}
        childBlock={childBlock}
        mediaItems={mediaItems}
      />
    )
  },
  'mfblocks/pension-calc': (_, block, key, childBlock) => {
    return (
      <PensionCalc key={key} {...block.attributes} childBlock={childBlock} />
    )
  },
  'mfblocks/tabs': (mediaItems, block, key) => {
    return (
      <Tabs
        key={key}
        mediaItems={mediaItems}
        {...block.attributes}
        content={block.innerBlocks.map(({ innerBlocks }, key) => innerBlocks)}
      />
    )
  },
  'mfblocks/hr': (_, block, key) => {
    return <Hr key={key} {...block.attributes} />
  },
  'mfblocks/trustpilot': (_, block, key) => {
    return <Trustpilot key={key} {...block.attributes} />
  },
  'mfblocks/glossary': (_, block, key) => {
    return <Glossary key={key} {...block.attributes} />
  },
  'mfblocks/model-portfolios': (_, block, key) => {
    return <ModelPortfolios key={key} {...block.attributes} />
  },
  'mfblocks/assets-breakdown': (_, block, key) => {
    return <AssetsBreakdown key={key} {...block.attributes} />
  },
  'mfblocks/investor-profile-tabs': (_, block, key) => {
    return <InvestorProfileTabs key={key} {...block.attributes} />
  },
  'mfblocks/lottie-animation': (mediaItems, block, key) => {
    return (
      <LottieAnimation
        key={key}
        {...block.attributes}
        mediaItems={mediaItems}
      />
    )
  },

  'mfblocks/expected-performance': (_, block, key) => (
    <ExpectedPerformance key={key} {...block.attributes} />
  ),

  'mfblocks/pricing-cards-list': (_, block, key) => (
    <PricingCardsList key={key} {...block.attributes} />
  ),

  'mfblocks/light-carousel': (_, block, key) => (
    <LightCarousel key={key} {...block.attributes} />
  ),

  'mfblocks/blog-entries': (_, block, key) => (
    <BlogEntries key={key} {...block.attributes} />
  ),

  'mfblocks/multi-product-pricing-calculator': (_, block, key) => (
    <MultiProductPricingCalculator key={key} {...block.attributes} />
  ),

  'mfblocks/images-carousel': (_, block, key) => (
    <ImagesCarousel key={key} {...block.attributes} />
  ),

  'mfblocks/app-download-button': (_, block, key) => (
    <AppDownloadButton key={key} {...block.attributes} />
  ),

  'mfblocks/crossword': (_, block, key) => (
    <Crossword key={key} {...block.attributes} />
  ),
}

export default function PageBlocks({ mediaItems, blocks }) {
  return (
    <div className="page-blocks">
      {blocks.map((block, index) => renderBlock(mediaItems, block, index))}
    </div>
  )
}
