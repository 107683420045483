import React, { Component } from 'react'

import { Container, Title, TraceContainer } from './BlockErrorBoundary.styles'
import { isDevEnv } from '../../utils/env'

type State = { caughtError: unknown | null }

export class BlockErrorBoundary extends Component<State, any> {
  constructor(props: any) {
    super(props)
    this.state = { caughtError: null }
  }

  static getDerivedStateFromError(error: unknown) {
    return { caughtError: error }
  }

  componentDidCatch(error: any, errorInfo: unknown) {
    this.setState({ caughtError: error })

    console.error(error, errorInfo)
  }

  render() {
    if (!!this.state.caughtError) {
      return (
        <Container>
          <Title>An error occurred while trying to render this block.</Title>

          {isDevEnv && (
            <TraceContainer>
              <strong>{this.state.caughtError?.message}</strong>

              <br />
              <br />

              <strong>Info:</strong>
              {this.state.caughtError?.stack
                ?.split('\n')
                .map((line: string) => (
                  <p key={line}>{line}</p>
                ))}
            </TraceContainer>
          )}
        </Container>
      )
    }

    return this.props.children
  }
}
