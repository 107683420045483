import DangerouslySetHtmlContent from 'dangerously-set-html-content'
import { graphql } from 'gatsby'
import React from 'react'

type Props = {
  content: string
}

export default function CustomHtml({ content }: Props) {
  return <DangerouslySetHtmlContent html={content} />
}

export const query = graphql`
  fragment htmlFields on WPGraphQL_CoreHtmlBlock {
    name
    saveContent
  }
`
