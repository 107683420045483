import styled, { css } from 'styled-components'

import { fromBreakpoint } from '../../../../utils/style/breakpoint'
import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'
import { ImageWidths } from '../../../common/Picture/types'
import { Props } from './Image'

function generateVerticalAlignmentStyle(
  verticalAlignment: NonNullable<Props['verticalAlignment']>
) {
  if (verticalAlignment === 'none') {
    return ''
  }

  if (verticalAlignment === 'bottom') {
    return css`
      display: flex;

      > picture {
        margin-top: auto;
      }
    `
  }
}

type ImageWidthWrapperProps = BaseBlockProps &
  ImageWidths & {
    verticalAlignment: NonNullable<Props['verticalAlignment']>
  }

export const ImageWidthWrapper = styled.div<ImageWidthWrapperProps>`
  img {
    ${({ xs }) => (xs ? `width: ${xs};` : `width: auto;`)}

    ${fromBreakpoint('sm')} {
      ${({ sm }) => (sm ? `width: ${sm};` : '')}
    }

    ${fromBreakpoint('md')} {
      ${({ md }) => (md ? `width: ${md};` : '')}
    }

    ${fromBreakpoint('lg')} {
      ${({ lg }) => (lg ? `width: ${lg};` : '')}
    }

    ${fromBreakpoint('xl')} {
      ${({ xl }) => (xl ? `width: ${xl};` : '')}
    }
  }

  ${({ hiddenAt }) => hideBlockAt({ hiddenAt })};
  ${({ marginBottom }) => marginBreakpoints({ marginBottom }, 0)};

  ${({ verticalAlignment }) =>
    generateVerticalAlignmentStyle(verticalAlignment)}
`
