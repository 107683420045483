import React, { FC, HTMLAttributes, ReactNode } from 'react'

import { BreakpointsImagesData, sources } from '../sources'
import { PictureEl } from './Picture.styles'
import { ImageWidths } from './types'

type Props = {
  className?: HTMLAttributes<FC>['className']
  children?: ReactNode
  width: ImageWidths
} & BreakpointsImagesData

export const Picture: FC<Props> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  children,
  width,
}) => (
  <PictureEl className={className} $widths={width}>
    {sources({ xl, lg, md, sm, xs })}

    <img
      className="preview-image"
      alt={xs?.alt?.length ? xs.alt : 'Moneyfarm image'}
      src={(xl || lg || md || sm || xs).url}
      loading="lazy"
    />
    {children}
  </PictureEl>
)
