import React, { FC } from 'react'

import SiteLink from '../../../common/SiteLink'
import AnimateOnScroll from '../../../common/AnimateOnScroll'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'
import { ImageWidthWrapper } from './Image.styles'
import { ImagePayload } from './types'
import { Picture } from '../../../common/Picture'
import { ImageWidths } from '../../../common/Picture/types'

export type Props = BaseBlockProps & {
  image: string // Stringified JSON.
  width: string // Stringified JSON.
  link: string
  animateOnScroll: boolean
  animationType: string
  animationDelay: string
  animationEasing: string
  className?: string
  verticalAlignment?: 'none' | 'bottom'
}

export const Image: FC<Props> = ({
  image,
  width,
  hiddenAt,
  marginBottom,
  link,
  animateOnScroll,
  animationType,
  animationDelay,
  animationEasing,
  className,
  verticalAlignment = 'none'
}) => {
  const imagePayload: ImagePayload = JSON.parse(image)
  const imageWidth: ImageWidths = JSON.parse(width)

  const ImageBlock = (
    <ImageWidthWrapper
      {...imageWidth}
      className={className}
      hiddenAt={hiddenAt}
      marginBottom={marginBottom}
      verticalAlignment={verticalAlignment}
    >
      <Picture {...imagePayload} width={imageWidth} />
    </ImageWidthWrapper>
  )

  const Block = animateOnScroll ? (
    <AnimateOnScroll
      animationType={animationType}
      animationDelay={animationDelay}
      animationEasing={animationEasing}
    >
      {ImageBlock}
    </AnimateOnScroll>
  ) : (
    ImageBlock
  )

  if (link) return <SiteLink to={link}>{Block}</SiteLink>

  return Block
}
