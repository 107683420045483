import styled, { css } from 'styled-components'

import gridTheme from '../../../utils/gridTheme'
import { ImageWidths } from './types'
import { ImageBreakpoints } from '../../../types/Image'

type PictureProps = {
  $widths: ImageWidths
}

export const PictureEl = styled.picture<PictureProps>`
  display: flex;
  justify-content: center;

  img {
    ${Object.entries(gridTheme.breakpoints)
      .map(([breakpoint, minWidth]) =>
        breakpoint === 'xs'
          ? css`
              width: ${({ $widths }: PictureProps) =>
                $widths.xs ? $widths.xs : 'auto'};
            `
          : css`
              @media screen and (min-width: ${minWidth}px) {
                ${({ $widths }: PictureProps) =>
                  $widths[breakpoint as ImageBreakpoints]
                    ? `width: ${$widths[breakpoint as ImageBreakpoints]};`
                    : ''}
              }
            `
      )
      .join('\n')}
  }
`
